import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrl = 'https://vosdellen-backend.next-levels.de/api';

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private router: Router
  ) {}

  post(
    endpoint: string,
    params: any = null,
    loader: any = null
  ): Observable<any> {
    const headers: any = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };

    if (this.platform.is('cordova')) {
      //return from(this.httpNative.post(__BASE_URL__ + endpoint, this.getHttpParams(params), headers)).pipe(map(data => <any>JSON.parse(data.data)), catchError(x => this.handleError(x, loader)));
    } else {
      return this.http.post(this.baseUrl + endpoint, params, { headers }).pipe(
        map((data) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const resp = <any>data;
          return resp;
        }),
        catchError((x) => this.handleError(x, loader))
      );
    }
  }

  handleError(err: HttpErrorResponse, loader: any = null) {
    if (loader) {
      loader.dismiss();
    }
    if (err.status === 401) {
      this.router.navigate(['login']);
    } else if (err.status === 500) {
      let errMessage = 'Ein unbekannter Fehler ist aufgetreten.';
      if (err.error && err.error.errorMessage) {
        errMessage = err.error.errorMessage;
      } else if (err.error && 'string' === typeof err.error) {
        try {
          const errObj = JSON.parse(err.error);
          errMessage = errObj.errorMessage;
        } catch (e) {}
      }
      // this.alertService.simpleAlert('Fehler!', errMessage);
    } else if (err.status === 404) {
      // this.alertService.simpleAlert('Fehler!', 'Seite nicht gefunden.');
    } else if (err.status <= 0) {
      // this.sharedService.simpleAlert('Fehler!', 'Sie haben schwaches bis kein Internet.');
    }
    return throwError(err);
  }

  getHttpParams(params) {
    if (params) {
      if (params instanceof Object || params instanceof Array) {
        return JSON.stringify(params);
      } else {
        return params;
      }
    } else {
      return '';
    }
  }
}
