import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  isRunning = new BehaviorSubject<boolean>(false);
  progress = new BehaviorSubject<number>(null);
  error = new BehaviorSubject<string>(null);
  maxProgress = new BehaviorSubject<number>(null);

  constructor() {}

  resetValues() {
    this.isRunning.next(false);
    this.progress.next(0);
    this.maxProgress.next(0);
    this.error.next(null);
  }
}
