import {Action} from '@ngrx/store';
import {Navigation} from '../../../models/navigation';


export enum NavigationActionTypes {
  INITIALIZE = '[NAVIGATION] Initialize Next Section',
  NEXT_SECTION = '[NAVIGATION] Next Section',
  PREV_SECTION = '[NAVIGATION] Previous Section',
  ENABLE_NEXT_SECTION = '[NAVIGATION] Enable Next Section',
  DISABLE_NEXT_SECTION = '[NAVIGATION] Disable Next Section',
  SET_NAVIGATION = '[NAVIGATION] Set Navigation',
  GET_NAVIGATION = '[NAVIGATION] Get Navigation'
}

export class InitializeSection implements Action {
  readonly type = NavigationActionTypes.INITIALIZE;
  constructor() { }
}

export class GetNavigation implements Action {
  readonly type = NavigationActionTypes.GET_NAVIGATION;
  constructor() {}
}

export class SetNavigation implements Action {
  readonly type = NavigationActionTypes.SET_NAVIGATION;
  constructor(public payload: Navigation) {}
}

export class GetNextSection implements Action {
  readonly type = NavigationActionTypes.NEXT_SECTION;
  constructor(public payload: Navigation) {
    payload.sectionIndex += 1;
    payload.nextSection += 1;
    payload.previousSection = payload.sectionIndex;
    payload.canNavBack = true;
    payload.canNavForward = false;
  }
}

export class GetPrevSection implements Action {
  readonly type = NavigationActionTypes.PREV_SECTION;
  constructor(public payload: Navigation) {
    payload.sectionIndex -= 1;
    payload.nextSection -= 1;
    payload.previousSection = payload.sectionIndex - 2;
    payload.canNavBack = payload.sectionIndex > 0;
    payload.canNavForward = true;
  }
}

export class EnableNextSection implements Action {
  readonly type = NavigationActionTypes.ENABLE_NEXT_SECTION;
  constructor(public payload: Navigation) {
    payload.canNavForward = true;
  }
}

export class DisableNextSection implements Action {
  readonly type = NavigationActionTypes.DISABLE_NEXT_SECTION;
  constructor(public payload: Navigation) {
    payload.canNavForward = false;
  }
}

export type NavigationAction =
  InitializeSection |
  GetNextSection |
  GetPrevSection |
  EnableNextSection |
  DisableNextSection |
  SetNavigation |
  GetNavigation; // mit | trennen
