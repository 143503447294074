import {
  MachineryInsuranceActionTypes,
  MachineryInsuranceActions,
} from './action';

import { MachineryInsurance } from '../../../models/insurances/MachineryInsurance';

const initialState: { payload: MachineryInsurance } = {
  payload: {
    insuranceType: 'Maschinen- u. Elektronikversicherung',
    nameOfPolicyHolder: null,
    firstNameOfContactPerson: null,
    lastNameOfContactPerson: null,
    phoneOfContactPerson: null,
    typeOfDamage: null,
    dateOfDamage: null,
    damageStreet: null,
    damageNumber: null,
    damagePostCode: null,
    damageLocation: null,
    constructionSite: null,
    damageDescription: null,
    policeProtocolMade: null,
    policeProtocolCarbonCopy: null,
    supervisorInformed: null,
    posInDeviceDirectory: null,
    designation: null,
    brand: null,
    type: null,
    yearOfManufacture: null,
    serialNumber: null,
    photosTaken: null,
    performingRepairer: null,
    repairCompany: null,
    repairFirstName: null,
    repairLastName: null,
    repairStreet: null,
    repairNumber: null,
    repairPc: null,
    repairLoc: null,
    repairPhone: null,
    paymentTo: null,
    iban: null,
    damageFiles: null,
    email: null,
    additionalMailAddresses: null,
  },
};

export const machineryReducer = (
  state: { payload: MachineryInsurance } = initialState,
  action: MachineryInsuranceActions
) => {
  switch (action.type) {
    case MachineryInsuranceActionTypes.GET_VALUE:
      return {
        ...state,
        payload: action.payload,
      };

    case MachineryInsuranceActionTypes.INIT_VALUE:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
};
