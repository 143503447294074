import {Action} from '@ngrx/store';
import {ConstructionPerformanceInsurance} from '../../../models/insurances/ConstructionPerformanceInsurance';

export enum ConstructionPerformanceInsuranceActionTypes {
  GET_VALUE = '[CONSTRUCTION_PERRFORMANCE_INSURANCE] Get Value',
  INIT_VALUE = '[CONSTRUCTION_PERRFORMANCE_INSURANCE] Init Value'
}


export class GetConstructionPerformanceInsurance implements Action {
  readonly type = ConstructionPerformanceInsuranceActionTypes.GET_VALUE;
  constructor(
    public payload: ConstructionPerformanceInsurance
  ) {}
}

export class InitConstructionPerformanceInsurance implements Action {
  readonly type = ConstructionPerformanceInsuranceActionTypes.INIT_VALUE;
  constructor() {}
}

export type ConstructionPerformanceInsuranceActions =
  GetConstructionPerformanceInsurance |
  InitConstructionPerformanceInsurance; // mit | trennen
