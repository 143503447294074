import {
  ConstructionPerformanceInsuranceActionTypes,
  ConstructionPerformanceInsuranceActions,
} from './action';

import { ConstructionPerformanceInsurance } from '../../../models/insurances/ConstructionPerformanceInsurance';

const initialState: { payload: ConstructionPerformanceInsurance } = {
  payload: {
    insuranceType: 'Bauleistungsversicherung',
    nameOfPolicyHolder: null,
    firstNameOfContactPerson: null,
    lastNameOfContactPerson: null,
    phoneOfContactPerson: null,
    dayOfDamage: null,
    becameAwareOfDamage: null,
    constructionStreet: null,
    constructionNumber: null,
    constructionPc: null,
    constructionLoc: null,
    alreadyInstalled: null,
    reportSuccessfulAt: null,
    responsiblePoliceStation: null,
    fileNumberOfStateProsecutorsOffice: null,
    damageAmount: null,
    damageDescription: null,
    constructionServicesAlreadyCompleted: null,
    constructionServicesAlreadyCompletedSince: null,
    constructionServicesAlreadyInUse: null,
    constructionServicesAlreadyInUseSince: null,
    constructionServicesAlreadyAccepted: null,
    whenAccepted: null,
    whoAcceptedFirstName: null,
    whoAcceptedLastName: null,
    whoAcceptedCompany: null,
    photosTaken: null,
    damageFiles: null,
    thirdPartyDamage: null,
    firstNameOfThird: null,
    lastNameOfThird: null,
    streetOfThird: null,
    numberOfThird: null,
    pcOfThird: null,
    locOfThird: null,
    wilfulDamage: null,
    iban: null,
    paymentTo: null,
    email: null,
    additionalMailAddresses: null,
  },
};

export const constructionPerformanceReducer = (
  state: { payload: ConstructionPerformanceInsurance } = initialState,
  action: ConstructionPerformanceInsuranceActions
) => {
  switch (action.type) {
    case ConstructionPerformanceInsuranceActionTypes.GET_VALUE:
      return {
        ...state,
        payload: action.payload,
      };

    case ConstructionPerformanceInsuranceActionTypes.INIT_VALUE:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
};
