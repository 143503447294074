import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-error',
  template: `
    <div class="error-container d-center">
      <div
        class="error-message p-3 text-center position-relative w-100 text-white"
      >
        {{ error }}
      </div>
    </div>
  `,
  styles: [
    '.error-container { width: 100%; height: 240px }',
    '.error-message { background: rgba(var(--ion-color-danger-rgb), 0.8); border-radius: 10px;}',
  ],
})
export class ErrorComponent implements OnInit {
  @Input() error: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    setTimeout(() => {
      this.modalController.dismiss();
    }, 3000);
  }
}
