import {
  FormsModule,
  ReactiveFormsModule,
  FormGroupDirective,
} from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { ApiService } from './shared/services/api/api.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DamageAmountsService } from './shared/services/damage-amount/damage-amounts.service';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpService } from './shared/services/http/http.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgCalendarModule } from 'ionic2-calendar';
import { NgModule } from '@angular/core';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ProgressService } from './shared/services/progress/progress.service';
import { RouteReuseStrategy } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { constructionPerformanceReducer } from './shared/services/state/construction-performance-insurance/reducer';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { machineryReducer } from './shared/services/state/machinery-insurance/reducer';
import { navigationReducer } from './shared/services/state/navigation/reducer';
import { publicLiabilityReducer } from './shared/services/state/public-liability-insurance/reducer';
import { registerLocaleData } from '@angular/common';
import { vehicleReducer } from './shared/services/state/vehicle-insurance/reducer';
import { HttpErrorInterceptor } from './shared/services/http.interceptor';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot({
      navigation: navigationReducer,
      constructionPerformance: constructionPerformanceReducer,
      vehicle: vehicleReducer,
      machinery: machineryReducer,
      publicLiability: publicLiabilityReducer,
    }),
    EffectsModule.forRoot([]),
    NgCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgProgressModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    DamageAmountsService,
    ProgressService,
    HttpService,
    ApiService,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
