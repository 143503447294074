import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { BehaviorSubject } from 'rxjs';
import { LogSettings } from '../../models/insurances/LogSettings';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  loggingEnabled = new BehaviorSubject<boolean>(false);
  logSettings = new BehaviorSubject<LogSettings>(null);

  constructor(private platform: Platform) {}

  log(type: string, log_message: string, object?: any) {
    const ERROR = { color: '\x1b[31m%s\x1b[0m', value: '[ERROR]' };
    const WARNING = { color: '\x1b[33m%s\x1b[0m', value: '[WARNING]' };
    const SERVICE = { color: '\x1b[35m%s\x1b[0m', value: '[SERVICE]' };
    const SIMPLE = { color: '', value: '[SIMPLE]' };

    switch (type) {
      case 'ERROR':
        return console.log(ERROR.color, ERROR.value, log_message, object);
      case 'WARNING':
        return console.log(WARNING.color, WARNING.value, log_message, object);
      case 'SERVICE':
        return console.log(SERVICE.color, SERVICE.value, log_message, object);
      case 'SIMPLE':
        return console.log(SIMPLE.value, log_message, object);
    }
  }

  logPlatform() {
    console.log('                Platform');
    console.log('Platforms: ', this.platform.platforms());
    console.log('Width: ', this.platform.width());
    console.log('Height: ', this.platform.height());
    console.log('');

    console.log('                Navigator');
    const isTouchDevice = ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
    console.log('Is touch device: ', isTouchDevice);
    if (isTouchDevice) {
      console.log('Max touch points: ', navigator.maxTouchPoints);
    }
  }
}
