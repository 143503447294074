import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { DataService } from './shared/services/data/data.service';
import { LoggingService } from './shared/services/logging/logging.service';
import { PlatformService } from './shared/services/platform/platform.service';
import { StorageService } from './shared/services/storage/storage.service';
import { ModalService } from './shared/services/modal/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isProduction = environment.production;

  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private dataService: DataService,
    private loggingService: LoggingService,
    private modalService: ModalService
  ) {
    platform.ready().then(async () => {
      this.dataService.initAll();
      let logSettings = {
        navigation: false,
        construction_performance_insurance: false,
        public_liability_insurance: false,
        vehicle_insurance: false,
        machinery_insurance: false,
      };
      logSettings =
        (await this.storageService.get(this.storageService.LOGGING_SETTINGS)) ??
        logSettings;
      this.loggingService.logSettings.next(logSettings);
      await this.storageService
        .get(this.storageService.LOGGING_ENABLED)
        .then((value) => {
          this.loggingService.loggingEnabled.next(value);
        });
      this.logStates(this.isProduction);
    });
  }

  logStates(isProduction: boolean) {
    if (!isProduction) {
      this.dataService.getNavigation().subscribe((state) => {
        if (
          this.loggingService.loggingEnabled.value &&
          this.loggingService.logSettings?.value?.navigation
        ) {
          this.loggingService.log('SIMPLE', '[STATE] NAVIGATION:', state);
        }
      });
      this.dataService
        .getConstructionPerformanceInsurance()
        .subscribe((state) => {
          if (
            this.loggingService.loggingEnabled.value &&
            this.loggingService.logSettings?.value
              ?.construction_performance_insurance
          ) {
            this.loggingService.log(
              'SIMPLE',
              `[STATE] BAULEISTUNGEVERSICHERUNG: ${state.toString()}`
            );
          }
        });
      this.dataService.getPublicLiabilityInsurance().subscribe((state) => {
        if (
          this.loggingService.loggingEnabled.value &&
          this.loggingService.logSettings?.value?.public_liability_insurance
        ) {
          this.loggingService.log(
            'SIMPLE',
            `[STATE] BETRIESHAFTPFLICHTVERSICHERUNG: ${state}`
          );
        }
      });
      this.dataService.getVehicleInsurance().subscribe((state) => {
        if (
          this.loggingService.loggingEnabled.value &&
          this.loggingService.logSettings?.value?.vehicle_insurance
        ) {
          this.loggingService.log(
            'SIMPLE',
            `[STATE] KFZ_VERSICHERUNG: ${state}`
          );
        }
      });
      this.dataService.getMachineryInsurance().subscribe((state) => {
        if (
          this.loggingService.loggingEnabled.value &&
          this.loggingService.logSettings?.value?.machinery_insurance
        ) {
          this.loggingService.log(
            'SIMPLE',
            `[STATE] MASCHINEN_U_ELEKTRONIKVERSICHERUNG: ${state}`
          );
        }
      });
    }
  }
}
