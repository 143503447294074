import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DataService } from '../../../../shared/services/data/data.service';
import { PlatformService } from '../../../../shared/services/platform/platform.service';

@Component({
  selector: 'app-end-screen',
  templateUrl: './end-screen.component.html',
  styleUrls: ['./end-screen.component.scss'],
})
export class EndScreenComponent implements OnInit {
  @Input() loading: true;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private data: DataService,
    private platform: PlatformService
  ) {}

  ngOnInit() {}

  dismissWithReset(isReset?) {
    window.location.reload();
  }
}
