import {
  PublicLiabilityInsuranceActionTypes,
  PublicLiabilityInsuranceActions,
} from './action';

import { PublicLiabilityInsurance } from '../../../models/insurances/PublicLiabilityInsurance';

const initialState: { payload: PublicLiabilityInsurance } = {
  payload: {
    insuranceType: 'Betriebshaftpflichtversicherung',
    nameOfPolicyHolder: null,
    firstNameOfContactPerson: null,
    lastNameOfContactPerson: null,
    phoneOfContactPerson: null,
    dayOfDamage: null,
    damageStreet: null,
    damageNumber: null,
    damagePostCode: null,
    damageLocation: null,
    constructionSite: null,
    otherPersonsInvolvedInTheDamage: null,
    whoWasInvolved: null,
    phoneNumberOfInvolved: null,
    argeExists: null,
    argeDocument: null,
    liabilityExists: null,
    insurerName: null,
    contractNumber: null,
    workMachinesCausedDamage: null,
    typeOfMachine: null,
    maximumSpeedInKmh: null,
    photosTaken: null,
    damageFiles: null,
    sitePhotosTaken: null,
    siteDamagePhotos: null,
    claimantCompany: null,
    claimantFirstName: null,
    claimantLastName: null,
    claimantStreet: null,
    claimantNumber: null,
    claimantPc: null,
    claimantLoc: null,
    claimantPhone: null,
    whatIsDamaged: null,
    estimatedAmountOfDamage: null,
    previousCondition: null,
    damageDescription: null,
    claimsJustified: null,
    claimJustification: null,
    damageCauserCompany: null,
    damageCauserFirstName: null,
    damageCauserLastName: null,
    damageCauserStreet: null,
    damageCauserNumber: null,
    damageCauserPc: null,
    damageCauserLoc: null,
    damageCauserPhone: null,
    client: null,
    order: null,
    specificallyManufactured: null,
    dateOfManufacture: null,
    considerRegistered: null,
    whatToComplain: null,
    whatForConsider: null,
    policeProtocolAvailable: null,
    policeProtocols: null,
    additionalQuestions: null,
    contractualDutyOfCare: null,
    countOfFeetToSecure: null,
    siteLastInspected: null,
    whoInspectedSite: null,
    dutyOfInvestigationAssumedAfterLine: null,
    commitmentDelegated: null,
    commitmentDelegatedToWhomFirstName: null,
    commitmentDelegatedToWhomLastName: null,
    commitmentDelegatedToWhomCompany: null,
    exploredForUndergroundLines: null,
    planWasPosted: null,
    undergroundLineInSpecifiedLocation: null,
    heightDeviationInCm: null,
    sideDeviationInCm: null,
    searchShaftsMade: null,
    distanceToTheDamageSite: null,
    instructedByRepresentative: null,
    firstNameOfRepresentative: null,
    lastNameOfRepresentative: null,
    crackDamageDueToWhatWork: null,
    compactionWorkEvidencePreservationExpertOpinionMade: null,
    compactionWorkExpertOpinionOnThePreservationOfEvidence: null,
    compactionWorkInformationOnThePrescribedDegreeOfCompaction: null,
    compactionWorkTypeOfEquipment: null,
    compactionWorkDataSheetOfUsedMachine: null,
    compactionWorkDistanceOfWorksFromDamagedObjects: null,
    pileDrivingEvidencePreservationExpertOpinionMade: null,
    pileDrivingExpertOpinionOnThePreservationOfEvidence: null,
    pileDrivingTypeOfEquipment: null,
    pileDrivingDataSheetOfUsedMachine: null,
    pileDrivingDistanceOfWorksFromDamagedObjects: null,
    drillDrivingEvidencePreservationExpertOpinionMade: null,
    drillDrivingExpertOpinionOnThePreservationOfEvidence: null,
    drillDrivingTypeOfEquipment: null,
    drillDrivingDataSheetOfUsedMachine: null,
    drillDrivingDistanceOfWorksFromDamagedObjects: null,
    othersWhatTypeOfWork: null,
    othersEvidencePreservationExpertOpinionMade: null,
    othersExpertOpinionOnThePreservationOfEvidence: null,
    othersTypeOfEquipment: null,
    othersDataSheetOfUsedMachine: null,
    othersDistanceOfWorksFromDamagedObjects: null,
    paymentTo: null,
    iban: null,
    postingIndicator: null,
    alreadyPaidTheBill: null,
    email: null,
    additionalMailAddresses: null,
  },
};

export const publicLiabilityReducer = (
  state: { payload: PublicLiabilityInsurance } = initialState,
  action: PublicLiabilityInsuranceActions
) => {
  switch (action.type) {
    case PublicLiabilityInsuranceActionTypes.GET_VALUE:
      return {
        ...state,
        payload: action.payload,
      };

    case PublicLiabilityInsuranceActionTypes.INIT_VALUE:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
};
