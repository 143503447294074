import {Action} from '@ngrx/store';
import {PublicLiabilityInsurance} from '../../../models/insurances/PublicLiabilityInsurance';

export enum PublicLiabilityInsuranceActionTypes {
  GET_VALUE = '[PUBLIC_LIABILITY_INSURANCE] Get Value',
  INIT_VALUE = '[PUBLIC_LIABILITY_INSURANCE] Init Value'
}


export class GetPublicLiabilityInsurance implements Action {
  readonly type = PublicLiabilityInsuranceActionTypes.GET_VALUE;
  constructor(
    public payload: PublicLiabilityInsurance
  ) {}
}

export class InitPublicLiabilityInsurance implements Action {
  readonly type = PublicLiabilityInsuranceActionTypes.INIT_VALUE;
  constructor() {}
}

export type PublicLiabilityInsuranceActions =
  GetPublicLiabilityInsurance |
  InitPublicLiabilityInsurance; // mit | trennen
