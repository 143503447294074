import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { ApiService } from '../../services/api/api.service';
import { ModalController } from '@ionic/angular';
import { NgProgressComponent } from 'ngx-progressbar';
import { ProgressService } from './../../services/progress/progress.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements AfterViewInit {
  @ViewChild('progressBar') progressBar: NgProgressComponent;

  @Input() options: {
    title: string;
    message?: string;
    hasLoadingBar?: boolean;
  };

  isInit = true;

  constructor(private progressService: ProgressService, private modalController: ModalController) {}

  ngAfterViewInit() {
    this.progressService.progress.subscribe((value) => {
      if (value) {
        this.progressBar.progressRef.set(
          Math.round((value / this.progressService.maxProgress.value) * 100)
        );
      }
    });
  }
}
