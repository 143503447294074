import {NavigationAction, NavigationActionTypes, SetNavigation} from './action';
import {Navigation} from '../../../models/navigation';

const initialState: { payload: Navigation } = {
  payload: {
    sectionIndex: 0,
    nextSection: 1,
    canNavForward: false,
    previousSection: -1,
    canNavBack: false,
    shadowSlidePhotos: false,
  }
 };

export const navigationReducer = (state: { payload: Navigation } = initialState, action: NavigationAction) => {

  switch (action.type) {

    case NavigationActionTypes.INITIALIZE:
      return { ...initialState };

    case NavigationActionTypes.GET_NAVIGATION:
      return {...state.payload};

    case NavigationActionTypes.SET_NAVIGATION:
      return {
        ...state,
        payload: action.payload
      };

    case NavigationActionTypes.NEXT_SECTION:
      return {
        ...state,
        payload: action.payload
      };

    case NavigationActionTypes.PREV_SECTION:
      return {
        ...state,
        payload: action.payload
      };

    case NavigationActionTypes.ENABLE_NEXT_SECTION:
      return {
        ...state,
        payload: action.payload
      };

    case NavigationActionTypes.DISABLE_NEXT_SECTION:
      return {
        ...state,
        payload: action.payload
      };

    default:
      return { ...state };

  }
};
