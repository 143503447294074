import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DamageAmountsService {
  /**
   * Format currency by given input
   *
   * @param input
   * @param blur
   * @returns
   */
  public formatCurrency(input: HTMLInputElement, blur = ''): string {
    let damageAmount = input.value;

    if (damageAmount === '') {
      return;
    }

    if (damageAmount.indexOf(',') >= 0) {
      const decimalPosition = damageAmount.indexOf(',');

      let leftSide = damageAmount.substring(0, decimalPosition);
      let rightSide = damageAmount.substring(decimalPosition);

      leftSide = this.formatNumber(leftSide);
      rightSide = this.formatNumber(rightSide);

      if (blur === 'blur') {
        rightSide += '00';
      }

      rightSide = rightSide.substring(0, 2);
      damageAmount = leftSide + ',' + rightSide;
    } else {
      damageAmount = this.formatNumber(damageAmount);
      damageAmount = damageAmount;

      if (blur === 'blur') {
        damageAmount += ',00';
      }
    }

    return damageAmount;
  }

  /**
   * Format number 1000 to 1.000
   *
   * @param n
   * @returns
   */
  private formatNumber(n: string) {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
