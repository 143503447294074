import {Action} from '@ngrx/store';

export enum MachineryInsuranceActionTypes {
  GET_VALUE = '[CONSTRUCTION_PERRFORMANCE_INSURANCE] Get Value',
  INIT_VALUE = '[CONSTRUCTION_PERRFORMANCE_INSURANCE] Initialize Value'
}


export class GetMachineryInsurance implements Action {
  readonly type = MachineryInsuranceActionTypes.GET_VALUE;
  constructor(
    public payload: GetMachineryInsurance
  ) {}
}

export class InitMachineryInsurance implements Action {
  readonly type = MachineryInsuranceActionTypes.INIT_VALUE;
  constructor() {}
}

export type MachineryInsuranceActions =
  GetMachineryInsurance |
  InitMachineryInsurance; // mit | trennen
