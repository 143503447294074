import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private platform: Platform) {}

  isAndroid() {
    return this.platform.is('android');
  }

  isNativeApp() {
    return (
      (this.platform.is('ios') || this.platform.is('android')) &&
      !this.platform.is('mobileweb')
    );
  }

  isNativeBrowser() {
    return this.platform.is('mobileweb');
  }

  isNative() {
    return (
      (this.platform.is('ios') && window.innerWidth < 768) ||
      (this.platform.is('android') && window.innerWidth < 768) ||
      (this.platform.is('mobileweb') && window.innerWidth < 768) ||
      (this.platform.is('mobile') && window.innerWidth < 768) &&
      !this.platform.is('tablet')
    );
  }

  getAppHeight() {
    return this.platform.height();
  }

  getAppWidth() {
    return this.platform.width();
  }
}
