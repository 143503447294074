import { EndScreenComponent } from 'src/app/modules/selection/modals/end-screen/end-screen.component';
import { ErrorComponent } from '../../modals/error/error.component';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProgressComponent } from '../../modals/progress/progress.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalController: ModalController) {}

  dismissModal(id: string) {
    return this.modalController.dismiss(null, null, id);
  }

  async presentProgressModal(options: { title: string; message?: string }) {
    const modal = await this.modalController.create({
      id: 'progress-modal',
      component: ProgressComponent,
      cssClass: 'progress-modal',
      backdropDismiss: false,
      componentProps: {
        options: {
          title: options.title,
          message: options.message ?? null,
        },
      },
    });
    return await modal.present();
  }

  async presentEndScreenModal() {
    const modal = await this.modalController.create({
      id: 'end-screen-modal',
      component: EndScreenComponent,
      cssClass: 'end-report',
      backdropDismiss: false,
    });
    // modal.onDidDismiss().then((data) => {
    //   this.configService.init();
    // });
    return await modal.present();
  }

  async presentErrorModal(errorMessage: string) {
    const modal = await this.modalController.create({
      id: 'error-modal',
      component: ErrorComponent,
      cssClass: 'show-error',
      backdropDismiss: false,
      showBackdrop: false,
      animated: false,
      componentProps: {
        error: errorMessage,
      },
    });

    return await modal.present();
  }
}
