import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Navigation } from '../../models/navigation';
import {
  DisableNextSection,
  EnableNextSection,
  GetNextSection,
  GetPrevSection,
  InitializeSection,
  SetNavigation,
} from '../state/navigation/action';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConstructionPerformanceInsurance } from '../../models/insurances/ConstructionPerformanceInsurance';
import {
  GetConstructionPerformanceInsurance,
  InitConstructionPerformanceInsurance,
} from '../state/construction-performance-insurance/action';
import { VehicleInsurance } from '../../models/insurances/VehicleInsurance';
import {
  GetVehicleInsurance,
  InitVehicleInsurance,
} from '../state/vehicle-insurance/action';
import { MachineryInsurance } from '../../models/insurances/MachineryInsurance';
import {
  GetMachineryInsurance,
  InitMachineryInsurance,
} from '../state/machinery-insurance/action';
import { PublicLiabilityInsurance } from '../../models/insurances/PublicLiabilityInsurance';
import {
  GetPublicLiabilityInsurance,
  InitPublicLiabilityInsurance,
} from '../state/public-liability-insurance/action';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  static isSummarySlide = new BehaviorSubject(false);
  navigation: Navigation;
  swiperChildren = [];

  constructor(
    private navigationStore: Store<{ navigation: Navigation }>,
    private constructionPerformanceStore: Store<{
      constructionPerformance: ConstructionPerformanceInsurance;
    }>,
    private vehicleInsuranceStore: Store<{ vehicle: VehicleInsurance }>,
    private machineryInsuranceStore: Store<{ machinery: MachineryInsurance }>,
    private publicLiabilityInsuranceStore: Store<{
      publicLiability: PublicLiabilityInsurance;
    }>
  ) {}

  initAll() {
    this.getNavigation().subscribe((navigation) => {
      this.navigation = JSON.parse(
        JSON.stringify((navigation as any).navigation.payload)
      );
    });

    this.initNavigation();
    this.initConstructionPerformanceInsurance();
    this.initPublicLiabilityInsurance();
    this.initVehicleInsurance();
    this.initMachineryInsurance();
  }

  getNavigation(): Observable<{ navigation: Navigation }> {
    return this.navigationStore.select((store) => store);
  }

  setNavigation(navigation: Navigation) {
    this.navigationStore.dispatch(new SetNavigation(navigation));
  }

  getNextSection() {
    this.navigationStore.dispatch(new GetNextSection(this.navigation));
  }

  getPrevSection() {
    this.navigationStore.dispatch(new GetPrevSection(this.navigation));
  }

  enableNextSection() {
    this.navigationStore.dispatch(
      new EnableNextSection(JSON.parse(JSON.stringify(this.navigation)))
    );
  }

  disableNextSection() {
    this.navigationStore.dispatch(
      new DisableNextSection(JSON.parse(JSON.stringify(this.navigation)))
    );
  }

  initNavigation() {
    this.navigationStore.dispatch(new InitializeSection());
  }

  getConstructionPerformanceInsurance(): Observable<{
    constructionPerformance: ConstructionPerformanceInsurance;
  }> {
    return this.constructionPerformanceStore.select((store) => store);
  }

  setSwiperChildren(children) {
    this.swiperChildren = children;
  }

  isActiveSite(site: string) {
    if (!this.swiperChildren[this.navigation.sectionIndex - 1]) {
      return;
    }
    return (
      this.swiperChildren[this.navigation.sectionIndex - 1] &&
      this.swiperChildren[this.navigation.sectionIndex - 1].toLowerCase() ===
        site.toLowerCase() &&
      this.navigation.sectionIndex > 0
    );
  }

  getIndexByName(name: string) {
    return this.swiperChildren.findIndex(
      (child) => child.toLowerCase() === name.toLowerCase()
    );
  }

  getSwiperLength() {
    return this.swiperChildren.length;
  }

  setConstructionPerformanceInsurance(payload) {
    this.constructionPerformanceStore.dispatch(
      new GetConstructionPerformanceInsurance(payload)
    );
  }

  initConstructionPerformanceInsurance() {
    this.constructionPerformanceStore.dispatch(
      new InitConstructionPerformanceInsurance()
    );
  }

  getVehicleInsurance(): Observable<{ vehicle: VehicleInsurance }> {
    return this.vehicleInsuranceStore.select((store) => store);
  }

  setVehicleInsurance(payload) {
    this.vehicleInsuranceStore.dispatch(new GetVehicleInsurance(payload));
  }

  initVehicleInsurance() {
    this.vehicleInsuranceStore.dispatch(new InitVehicleInsurance());
  }

  getMachineryInsurance(): Observable<{ machinery: MachineryInsurance }> {
    return this.machineryInsuranceStore.select((store) => store);
  }

  setMachineryInsurance(payload) {
    this.machineryInsuranceStore.dispatch(new GetMachineryInsurance(payload));
  }

  initMachineryInsurance() {
    this.machineryInsuranceStore.dispatch(new InitMachineryInsurance());
  }

  getPublicLiabilityInsurance(): Observable<{
    publicLiability: PublicLiabilityInsurance;
  }> {
    return this.publicLiabilityInsuranceStore.select((store) => store);
  }

  setPublicLiabilityInsurance(payload) {
    this.publicLiabilityInsuranceStore.dispatch(
      new GetPublicLiabilityInsurance(payload)
    );
  }

  initPublicLiabilityInsurance() {
    this.publicLiabilityInsuranceStore.dispatch(
      new InitPublicLiabilityInsurance()
    );
  }
}
