import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public TUTORIAL_SEEN = 'TUTORIAL_SEEN';
  public NAVIGATION_ALWAYS_ENABLED = 'NAVIGATION_ALWAYS_ENABLED';
  public SKIP_INTRO = 'SKIP_INTRO';
  public LOGGING_ENABLED = 'LOGGING_ENABLED';
  public LOGGING_SETTINGS = 'LOGGING_SETTINGS';

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    this._storage = await this.storage.create();
  }

  async set(key: string, obj: any) {
    await this.storage.set(key, JSON.stringify(obj));
  }

  async get(key: string) {
    return JSON.parse(await this.storage.get(key));
  }

  async remove(key: string) {
    await this.storage.remove(key);
  }
}
