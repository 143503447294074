import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalService } from './../modal/modal.service';
import { ProgressService } from '../progress/progress.service';
import { __BASE_URL__ } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = __BASE_URL__;

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private progressService: ProgressService,
    private modalController: ModalController
  ) {}

  postResult(insurance: any) {
    let isInit = true;

    return this.http
      .post(this.baseUrl, insurance, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        map((data: any) => {
          if (isInit && data.total) {
            this.progressService.isRunning.next(true);
            this.modalService.presentProgressModal({
              title: 'Fast fertig!',
              message: `Ihre Daten werden gesammelt und abgesendet.<br />
                Bitte haben Sie einen Moment Geduld.`,
            });
            this.progressService.maxProgress.next(data.total);
            isInit = false;
          }

          if (data.loaded) {
            this.progressService.progress.next(data.loaded);
          }

          if (data.status && this.progressService.isRunning.value) {
            this.handleResponse(data.status);
          }
        }),
        catchError((err) => this.handleResponse(err.status, err))
      );
  }

  async handleResponse(responseStatus: number, err?: string) {
    switch (responseStatus) {
      case 201:
        this.modalService.presentEndScreenModal();
        break;
      case 404:
        this.modalService.presentErrorModal(
          'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut'
        );
        return throwError(err);
    }
    this.progressService.resetValues();
  }
}
