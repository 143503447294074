import {Action} from '@ngrx/store';

export enum VehicleInsuranceActionTypes {
  GET_VALUE = '[CONSTRUCTION_PERRFORMANCE_INSURANCE] Get Value',
  INIT_VALUE = '[CONSTRUCTION_PERRFORMANCE_INSURANCE] Initialize Value'
}


export class GetVehicleInsurance implements Action {
  readonly type = VehicleInsuranceActionTypes.GET_VALUE;
  constructor(
    public payload: GetVehicleInsurance
  ) {}
}

export class InitVehicleInsurance implements Action {
  readonly type = VehicleInsuranceActionTypes.INIT_VALUE;
  constructor() {}
}

export type VehicleInsuranceActions =
  GetVehicleInsurance |
  InitVehicleInsurance; // mit | trennen
